/* eslint-disable sort-keys */
import { notification } from 'antd';
import type { NotificationPlacement } from 'antd/es/notification/interface';
import { ReactNode } from 'react';

export enum NotificationStatus {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info'
}

interface Props {
	header: ReactNode;
	message?: ReactNode;
	durationInSeconds?: number;
	status: NotificationStatus;
	placement?: NotificationPlacement;
	className?: string;
	closeIcon?: ReactNode;
}
const queueNotification = ({
	header,
	closeIcon,
	className,
	message,
	durationInSeconds = 4.5,
	status,
	placement
}: Props) => {
	const args = {
		className: `scale-90 ${className}`,
		closeIcon,
		message: header,
		description: message,
		duration: durationInSeconds,
		placement: placement || 'topRight'
	};

	// queues notifcation
	notification[status](args);
};

export default queueNotification;

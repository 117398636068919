// Copyright 2022-2023 @Polkasafe/polkaSafe-ui authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

'use client';

import { createContext, useContext, useEffect, useMemo, useState, Context, ReactNode, useCallback } from 'react';
import { FIREBASE_URL_PROD } from '../constants/firebaseUrls';
import { usePrivy } from '@privy-io/react-auth';

export interface UserDetailsContextType {
	address: string;
	email: string;
	setEmail: React.Dispatch<React.SetStateAction<string>>;
	connectWallet: (address: string) => Promise<void>;
	openJoinWaitlistModal: boolean;
	setOpenJoinWaitlistModal: React.Dispatch<React.SetStateAction<boolean>>;
	loading: boolean;
}

export const UserDetailsContext: Context<UserDetailsContextType> = createContext({} as UserDetailsContextType);

export interface UserDetailsContextProviderProps {
	children?: ReactNode;
}

export function UserDetailsContextProvider({ children }: UserDetailsContextProviderProps): ReactNode {
	const [userAddress, setUserAddress] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [openJoinModal, setOpenJoinModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const { authenticated, user, logout } = usePrivy();

	const connectWallet = useCallback(async (address: string) => {
		if (!address) return;

		setLoading(true);
		const joinWaitlistRes = await fetch(`${FIREBASE_URL_PROD}/login`, {
			body: JSON.stringify({
				address: address
			}),
			headers: {
				Accept: 'application/json',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'Content-Type': 'application/json'
			},
			method: 'POST'
		});
		const { data, error } = (await joinWaitlistRes.json()) as {
			data: { address: string; email: string };
			error: string;
		};
		console.log('res', data, error);
		if (data && !error) {
			setUserAddress(data.address);
			setEmail(data.email);
			if (!data.email || data.email === '') {
				setOpenJoinModal(true);
			}
		} else {
			logout();
		}
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (authenticated && user) {
			connectWallet(user.wallet?.address || '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authenticated, user]);

	const value = useMemo(
		() => ({
			address: userAddress,
			connectWallet,
			email,
			loading,
			openJoinWaitlistModal: openJoinModal,
			setEmail,
			setOpenJoinWaitlistModal: setOpenJoinModal
		}),
		[userAddress, connectWallet, email, openJoinModal, loading]
	);

	return <UserDetailsContext.Provider value={value}>{children}</UserDetailsContext.Provider>;
}

export function useGlobalUserDetailsContext() {
	return useContext(UserDetailsContext);
}

import React from 'react';

const SplashScreen = () => {
	return (
		<div className='h-screen w-full flex justify-center items-center bg-[#161616]'>
			<img
				src='aigen-logo.png'
				alt='Aigen Logo'
				className='h-[30px] w-[140px]'
			/>
		</div>
	);
};

export default SplashScreen;

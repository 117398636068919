import React, { useCallback, useEffect, useState } from 'react';
import Odometer from 'react-odometerjs';

import 'odometer/themes/odometer-theme-default.css';
import { FIREBASE_URL_PROD } from '../../constants/firebaseUrls';

const MoreFeatures = () => {
	const [value, setValue] = useState(0);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setValue(200);
		}, 500);

		return () => clearTimeout(timeout);
	}, []);

	const getNodes = useCallback(async () => {
		const getNodesRes = await fetch(`${FIREBASE_URL_PROD}/getNodesInWaitlist`, {
			headers: {
				Accept: 'application/json',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'Content-Type': 'application/json'
			},
			method: 'POST'
		});
		const { data, error } = (await getNodesRes.json()) as {
			data: { nodes: number };
			error: string;
		};

		if (data && !error) {
			setValue(data.nodes);
		}
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			getNodes();
		}, 5000);

		return () => clearInterval(interval);
	}, [getNodes]);
	return (
		<div className='w-full flex flex-col mt-[100px] lg:mt-[120px] px-8 md:px-12'>
			<p className='text-white font-semibold text-[32px] md:text-[40px] lg:text-[50px] text-center mb-3'>
				Nodes in Waitlist
			</p>
			<p className='text-white font-bold text-[80px] sm:text-[100px] md:text-[129px] text-center mt-8 mb-4'>
				<Odometer
					// animation='count'
					format='d'
					duration={1000}
					value={value}
				/>
				+
			</p>
			<img
				src='more-feature.gif'
				alt='More features'
			/>
		</div>
	);
};

export default MoreFeatures;

import { usePrivy } from '@privy-io/react-auth';
import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { FIREBASE_URL_PROD } from '../constants/firebaseUrls';
import queueNotification, { NotificationStatus } from '../ui-components/QueueNotification';
import { CloseOutlined } from '@ant-design/icons';
import { useGlobalUserDetailsContext } from '../context/UserDetailsContext';

const JoinWaitlistEmail = ({ onCancel }: { onCancel: () => void }) => {
	const [email, setEmail] = useState<string>('');
	const [emailValid, setEmailValid] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);
	const { setEmail: setUserEmail } = useGlobalUserDetailsContext();

	const { user } = usePrivy();

	useEffect(() => {
		const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		if (email.match(validRegex)) {
			setEmailValid(true);
		} else {
			setEmailValid(false);
		}
	}, [email]);

	const joinWaitlist = async () => {
		if (user && user.wallet && user.wallet.address && email) {
			setLoading(true);
			const joinWaitlistRes = await fetch(`${FIREBASE_URL_PROD}/addToWaitlist`, {
				body: JSON.stringify({
					address: user.wallet.address,
					email: email
				}),
				headers: {
					Accept: 'application/json',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'Content-Type': 'application/json'
				},
				method: 'POST'
			});
			const { data, error } = (await joinWaitlistRes.json()) as {
				data: { address: string; email: string };
				error: string;
			};
			console.log('res', data, error);

			if (data && !error) {
				queueNotification({
					header: 'Congratulations!',
					message: 'You have joined the Waitlist.',
					status: NotificationStatus.SUCCESS
				});
				setUserEmail(data.email);
				onCancel();
				setLoading(false);
			} else {
				queueNotification({
					header: 'Error!',
					message: error,
					status: NotificationStatus.ERROR
				});
				setLoading(false);
			}
		}
	};
	return (
		<Form>
			<label className='text-primary font-normal text-xs leading-[13px] block mb-[5px]'>Email</label>
			<div className='flex items-center gap-x-[10px]'>
				<article className='w-full'>
					<Form.Item
						className='border-0 outline-0 my-0 p-0'
						name='email'
						rules={[{ required: true }]}
						validateStatus={email && !emailValid ? 'error' : 'success'}
						help={email && !emailValid && 'Please enter a Valid Email'}
					>
						<Input
							type='email'
							id='email'
							onChange={(e) => setEmail(e.target.value)}
							placeholder='Enter your Email'
							value={email}
							className='w-full text-sm font-normal leading-[15px] border-0 outline-0 p-3 placeholder:text-[#505050] bg-bg-secondary rounded-lg text-white pr-20'
						/>
					</Form.Item>
					<div className='mt-6 flex justify-between px-2 items-center'>
						<Button
							disabled={loading}
							icon={<CloseOutlined className='text-[#5A5A5A] h-2 w-2' />}
							className='border border-solid border-[#5A5A5A] text-[#5A5A5A] bg-transparent'
							onClick={onCancel}
						>
							Cancel
						</Button>
						<Button
							loading={loading}
							disabled={!user?.wallet?.address || !email || !emailValid}
							onClick={() => joinWaitlist()}
							className='bg-primary text-black w-20'
						>
							Join
						</Button>
					</div>
				</article>
			</div>
		</Form>
	);
};

export default JoinWaitlistEmail;

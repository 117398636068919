import React, { ReactNode, useEffect, useState } from 'react';
import NavHeader from './NavHeader';
import { Layout } from 'antd';
import Footer from './Footer';
import './styles.css';
import { usePrivy } from '@privy-io/react-auth';
import SplashScreen from './SplashScreen';

const AppLayout = ({ children }: { children: ReactNode }) => {
	const [isLayoutReady, setIsLayoutReady] = useState<boolean>(false);
	const { ready } = usePrivy();

	useEffect(() => {
		if (ready) {
			setIsLayoutReady(true);
		}
	}, [ready]);

	return isLayoutReady ? (
		<Layout className='background font-sans'>
			<Layout.Header className='sticky top-0 left-0 h-[100px] z-[100] flex items-center justify-center py-4 px-8 md:px-12 bg-transparent'>
				<NavHeader />
			</Layout.Header>
			<Layout className='min-h bg-transparent mb-12'>
				<Layout.Content>{children}</Layout.Content>
			</Layout>
			<Layout.Footer className='bg-primary p-8'>
				<Footer />
			</Layout.Footer>
		</Layout>
	) : (
		<SplashScreen />
	);
};

export default AppLayout;

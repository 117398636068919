import './styles.css';
import { useLogin, usePrivy } from '@privy-io/react-auth';
import React from 'react';
import { useGlobalUserDetailsContext } from '../../context/UserDetailsContext';

const HeroComponent = () => {
	const { authenticated } = usePrivy();

	const { connectWallet, email, setOpenJoinWaitlistModal, loading } = useGlobalUserDetailsContext();

	const { login } = useLogin({
		onComplete(user) {
			connectWallet(user?.wallet?.address || '');
		}
	});

	return (
		<div className='px-8 md:px-12 mt-6'>
			<div className='rounded-[20px] md:rounded-[30px] relative w-full h-[500px] lg:h-[600px] p-8 overflow-hidden'>
				<img
					className='absolute h-[105%] w-[105%] top-0 left-0 z-0'
					src='bg-hero.png'
					alt='bg-hero'
				/>
				<div className='z-10 relative h-full flex flex-col-reverse justify-between md:justify-start md:flex-col gap-y-8'>
					<div className='flex justify-center md:justify-end'>
						<div className='bg-[rgba(7,7,8,0.80)] border border-[#1C1C21] border-solid rounded-[100px] py-3 px-4 flex items-center gap-x-2'>
							<a
								href='https://twitter.com/AigenLayer'
								target='_blank'
								className='flex p-4 items-center justify-center bg-[#1C1C21] border border-solid border-[#2F2F37] rounded-full'
								rel='noreferrer'
							>
								<img
									src='twitter.png'
									alt='twitter'
								/>
							</a>
							<a
								href='https://discord.gg/GrReCvdH'
								target='_blank'
								className='flex p-4 items-center justify-center bg-[#1C1C21] border border-solid border-[#2F2F37] rounded-full'
								rel='noreferrer'
							>
								<img
									src='discord.png'
									alt='discord'
								/>
							</a>
							<a
								href='https://t.me/+7Oxkp-MImC5hOWE9'
								target='_blank'
								className='flex p-4 items-center justify-center bg-[#1C1C21] border border-solid border-[#2F2F37] rounded-full'
								rel='noreferrer'
							>
								<img
									src='telegram.png'
									alt='telegram'
								/>
							</a>
						</div>
					</div>
					<div>
						<div className='w-full md:w-[50%] flex flex-col gap-y-2 mt-4 md:mt-0'>
							<p className='font-bold text-white text-[24px] md:text-[32px] lg:text-[50px] font-manrope'>
								Building the Modular Intelligence Layer for AI
							</p>
							<p className='text-[14px] md:text-[18px] lg:text-[24px] text-[#CACACE] font-manrope'>
								AiGen supercharges the AI stack with a Modular Controller VM and a novel light-weight decentralized
								scorer.
							</p>
							<div className='flex items-center justify-center md:justify-start gap-x-4 mt-6'>
								<a
									href='http://docs.aigenlayer.org/'
									target='_blank'
									className='text-[10px] sm:text-[12px] md:text-[14px] border border-solid border-[#5A5A5A] text-white bg-transparent w-[150px] md:w-[200px] py-[12px] px-[18px] md:py-[18px] md:px-[24px] flex items-center justify-center rounded-[18px]'
									rel='noreferrer'
								>
									Learn More
								</a>
								<button
									disabled={loading}
									onClick={!authenticated ? () => login() : !email ? () => setOpenJoinWaitlistModal(true) : undefined}
									style={{
										background:
											email && authenticated
												? 'transparent'
												: 'linear-gradient(106deg, #FFF -11.29%, #EEB732 49.29%, #E94410 102.26%)'
									}}
									className={`text-[10px] sm:text-[12px] md:text-[14px] text-black font-semibold w-[150px] md:w-[200px] py-[12px] px-[18px] md:py-[18px] md:px-[24px] flex items-center justify-center rounded-[18px] ${email && 'connect-button'}`}
								>
									{email && authenticated ? (
										<span>
											<span className='nav-text active'>You&apos;re on the list</span>
											<span className='sm:text-[13px] md:text-[15px]'> 🎉</span>
										</span>
									) : (
										<span className='text-black'>Join Waitlist</span>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className='absolute right-0 top-[-20px]'>
					<img
						src='hero.gif'
						alt='lines'
						className='h-[500px] w-[500px] lg:h-[600px] lg:w-[600px]'
					/>
				</div>
			</div>
		</div>
	);
};

export default HeroComponent;

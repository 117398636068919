/* eslint-disable no-tabs */
import { Button, Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import queueNotification, { NotificationStatus } from '../ui-components/QueueNotification';
// import { FIREBASE_URL_PROD } from '../constants/firebaseUrls';
// import queueNotification, { NotificationStatus } from '../ui-components/QueueNotification';

const Footer = () => {
	const [email, setEmail] = useState<string>('');
	const [emailValid, setEmailValid] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(false);

	const getURL =
		'https://docs.google.com/spreadsheets/d/e/2PACX-1vSFY7orQff7a_mDfP3ODW_zbKF4IQQYveTfJGmID1vSwzHphNeg4qowoxG2DZ6YKF3NUtlxRMqeEBkn/pub?output=csv';
	const scriptUrl =
		'https://script.google.com/macros/s/AKfycbxWE6EGwtFRrjRGyJvejaZcJ5t9WQYaC6_1_b_zCkNTr13Cvnq3mRuKhrObHqM4Qlo/exec';

	useEffect(() => {
		const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		if (email.match(validRegex)) {
			setEmailValid(true);
		} else {
			setEmailValid(false);
		}
	}, [email]);

	const subscribeEmail = async () => {
		if (email && emailValid) {
			setLoading(true);
			const formData = new FormData();

			formData.append('email', email);

			const res = await fetch(getURL, { method: 'GET' });
			const csvSheetData = await res.text();
			const sheetEmails = csvSheetData.split('\n');
			console.log(sheetEmails);
			if (sheetEmails?.some((s) => s.split('\r')[0] === email)) {
				queueNotification({
					header: 'Failed!',
					message: 'Email Exists',
					status: NotificationStatus.ERROR
				});
				setLoading(false);
				return;
			}
			fetch(scriptUrl, {
				body: formData,
				method: 'POST'
			})
				.then(() => {
					console.log('SUCCESSFULLY SUBMITTED');
					setLoading(false);
					queueNotification({
						header: 'Subscribed!',
						status: NotificationStatus.SUCCESS
					});
					setEmail('');
				})
				.catch((err) => {
					console.log(err);
					queueNotification({
						header: 'Error!',
						message: err,
						status: NotificationStatus.ERROR
					});
					setLoading(false);
				});

			// const joinWaitlistRes = await fetch(`${FIREBASE_URL_PROD}/subscribeEmail`, {
			// 	body: JSON.stringify({
			// 		email: email
			// 	}),
			// 	headers: {
			// 		Accept: 'application/json',
			// 		// eslint-disable-next-line @typescript-eslint/naming-convention
			// 		'Content-Type': 'application/json'
			// 	},
			// 	method: 'POST'
			// });
			// const { data, error } = (await joinWaitlistRes.json()) as {
			// 	data: { address: string; email: string };
			// 	error: string;
			// };
			// console.log('res', data, error);

			// if (data && !error) {
			// 	queueNotification({
			// 		header: 'Congratulations!',
			// 		message: 'Subscribed.',
			// 		status: NotificationStatus.SUCCESS
			// 	});
			// 	setLoading(false);
			// } else {
			// 	queueNotification({
			// 		header: 'Error!',
			// 		message: error,
			// 		status: NotificationStatus.ERROR
			// 	});
			// 	setLoading(false);
			// }
		}
	};
	return (
		<div>
			<div className='flex justify-center'>
				<img
					src='aigen-logo-black.png'
					alt='Aigen Logo'
					className='block md:hidden h-[25px] w-[120px]'
				/>
			</div>
			<div className='flex flex-col items-center mt-10 md:mt-12'>
				<div className='hidden lg:flex flex-col font-manrope items-center'>
					<p className='text-[#191D23] text-[42px] font-bold relative mb-2'>
						<span className='z-10 relative'>Are you building the next-gen AI application?</span>
						<div className='absolute h-[5px] w-[105%] bg-[#E35A18] bottom-3 -right-6 z-0'></div>
					</p>
					<p className='text-[#191D23] text-[22px] relative w-[70%] text-center'>
						Contribute with your LLM models, Provide Compute, Build Controllers and Scorers to earn rewards.
					</p>
				</div>
				<div className='flex lg:hidden flex-col items-center font-manrope'>
					<p className='text-[#191D23] text-[20px] xs:text-[24px] sm:text-[32px] font-bold relative'>
						<span className='z-10 relative'>Are you building the next-gen</span>
						<div className='absolute h-[5px] w-[110%] bg-[#E35A18] bottom-1 -right-3 z-0'></div>
					</p>
					<p className='text-[#191D23] text-[20px] xs:text-[24px] sm:text-[32px] font-bold relative'>
						<span className='z-10 relative'>AI application?</span>
						<div className='absolute h-[5px] w-[110%] bg-[#E35A18] bottom-1 -right-3 z-0'></div>
					</p>
					<p className='text-[#191D23] text-[14px] md:text-[22px] relative text-center mt-4'>
						Contribute with your LLM models, Provide Compute, Build Controllers and Scorers to earn rewards.
					</p>
				</div>
				<div className='relative'>
					<Form.Item
						className='border-0 outline-0 my-0 p-0'
						name='email'
						rules={[{ required: true }]}
						validateStatus={email && !emailValid ? 'error' : 'success'}
						help={email && !emailValid && 'Please enter a Valid Email'}
					>
						<Input
							onChange={(e) => setEmail(e.target.value)}
							id='email'
							name='email'
							value={email}
							placeholder='Enter your email'
							className='mt-8 bg-transparent lg:w-[400px] border border-solid border-[rgba(0,0,0,0.29)] rounded-[16px] p-3 pr-28 text-[16px] outline-0 placeholder:text-[rgba(0,0,0,0.78)]'
						/>
					</Form.Item>
					<div className='absolute right-4 top-[50%]'>
						<Button
							loading={loading}
							disabled={!email || !emailValid}
							onClick={subscribeEmail}
							className='bg-[rgba(255,255,255,0.60)] border-none outline-none px-6 text-[#191D23]'
						>
							Subscribe
						</Button>
					</div>
				</div>
				<div className='flex justify-between items-center flex-col md:flex-row gap-y-3 w-full mt-12'>
					<img
						src='aigen-logo-black.png'
						alt='Aigen Logo'
						className='hidden md:block h-[25px] w-[120px]'
					/>
					<div className='flex items-center gap-x-5 text-[#191D23]'>
						<span className='cursor-pointer'>About Us</span>
						<a
							href='http://docs.aigenlayer.org/'
							target='_blank'
							className='cursor-pointer text-[#191D23]'
							rel='noreferrer'
						>
							Docs
						</a>
						<a
							href='mailto:hal@aigenlayer.org'
							className='cursor-pointer text-[#191D23]'
						>
							Contact Us
						</a>
						<a
							href='https://drive.google.com/file/d/15QTSuVMyMfumJ8MKFYKDeN4ZfYr5wIHo/view?usp=sharing'
							className='cursor-pointer text-[#191D23]'
							target='_blank'
							rel='noreferrer'
						>
							Brand Guidelines
						</a>
					</div>
					<div className='flex items-center gap-x-5'>
						<a
							href='https://twitter.com/AigenLayer'
							target='_blank'
							className='flex p-4 items-center justify-center border border-solid border-[#424449] rounded-full'
							rel='noreferrer'
						>
							<img
								src='twitter-black.png'
								alt='twitter'
							/>
						</a>
						<a
							href='https://discord.gg/GrReCvdH'
							target='_blank'
							className='flex p-4 items-center justify-center border border-solid border-[#424449] rounded-full'
							rel='noreferrer'
						>
							<img
								src='discord-black.png'
								alt='discord'
							/>
						</a>
						<a
							href='https://t.me/+7Oxkp-MImC5hOWE9'
							target='_blank'
							className='flex p-4 items-center justify-center border border-solid border-[#424449] rounded-full'
							rel='noreferrer'
						>
							<img
								src='telegram-black.png'
								alt='telegram'
							/>
						</a>
					</div>
				</div>
				<Divider className='border-[#787878]' />
				<div className='flex justify-between items-center flex-col md:flex-row gap-y-3 text-[#52525B] w-full'>
					<span>© Copyright 2024, All Rights Reserved</span>
					<div className='flex items-center gap-x-4'>
						<span>Privacy Policy</span>
						<span>Terms & Conditions</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;

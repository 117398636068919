import React from 'react';
import './styles.css';
import Banner from '../../Banner';

const Ecosystem = () => {
	const icons1 = [
		{
			height: 40,
			src: 'ionet-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'aioz-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'aethir-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'cudos-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'fluence-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'iexec-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'inferix-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'netmind-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'nosana-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'opsec-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'render-logo.jpg',
			width: 140
		}
	];
	const icons2 = [
		{
			height: 40,
			src: 'ocean-logo.png',
			width: 60
		},
		{
			height: 40,
			src: 'oraichain-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'bittensor-text.png',
			width: 120
		},
		{
			height: 40,
			src: 'synternet-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'gensyn-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'allora-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'olas-logo.jpg',
			width: 80
		},
		{
			height: 40,
			src: 'dain-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'deepsouth-logo.png',
			width: 80
		},
		{
			height: 40,
			src: 'morph-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'box-logo.png',
			width: 60
		},
		{
			height: 40,
			src: 'kip-logo.png',
			width: 120
		},
		{
			height: 40,
			src: 'flock-logo.png',
			width: 120
		}
	];
	return (
		<div className='w-full h-full flex flex-col mt-[100px] lg:mt-[120px] px-8 md:px-12 relative'>
			<p className='text-white font-semibold text-[32px] md:text-[40px] lg:text-[50px] mb-3'>Ecosystem</p>
			<p className='text-[#ABABAB] text-[12px] md:text-[18px] font-medium'>
				Aigen Layer&apos;s mission is to create a unified coordination layer that fosters seamless integration and
				interoperability among the AI tech stack, including LLMs and compute layers.
			</p>
			<div className='w-full mt-12 relative h-[500px] md:h-[400px] flex'>
				<div className='rounded-t-[23px] h-[60px] w-[120px] md:h-[100px] md:w-[200px] border-t border-r border-l border-solid border-[rgba(92,34,4,0.60)] px-4 py-2 box relative z-10 flex items-center justify-center'>
					<span className='text-white text-[22px] md:text-[32px] font-semibold'>Compute</span>
				</div>
				<div className='relative h-[60px] md:h-[100px] w-full overflow-hidden flex items-center px-2'>
					<Banner
						images={icons1}
						duration={18}
					/>
				</div>
				<div
					// style={{ background: 'linear-gradient(to right, #161616, #F4F2F2)' }}
					className='absolute top-[60px] md:top-[100px] z-0 w-full h-[420px]  md:h-[280px] rounded-b-[23px] rounded-tr-[23px] border border-[rgba(92,34,4,0.60)] flex items-start md:items-center md:justify-start justify-center py-8 px-16'
				>
					<div className='w-full md:w-[50%] text-[12px] md:text-[18px] text-[#999999] font-medium'>
						By plugging into AiGen, compute providers can optimize resource utilization and streamline AI workflows.
						AiGen&apos;s coordination layer facilitates efficient task allocation, load balancing, and resource
						provisioning, maximizing the throughput and performance of compute resources.
					</div>
					<img
						className='absolute bottom-0 right-0'
						src='ecosystem-1.png'
					/>
				</div>
			</div>
			<div className='w-full mt-12 relative h-[500px] md:h-[400px] flex'>
				<div className='relative h-[60px] md:h-[100px] w-full overflow-hidden flex items-center px-2'>
					<Banner images={icons2} />
				</div>
				<div className='box-2 rounded-t-[23px] h-[60px] w-[200px] md:h-[100px] md:w-[250px] border-t border-r border-l border-solid border-[rgba(92,34,4,0.60)] px-4 py-2 relative z-10 flex items-center justify-center'>
					<span className='text-white text-[14px] sm:text-[16px] md:text-[26px] font-semibold'>LLMs & Apps</span>
				</div>
				<div
					// style={{ background: 'linear-gradient(to right, #161616, #F4F2F2)' }}
					className='absolute top-[60px] md:top-[100px] z-0 w-full h-[420px]  md:h-[280px] rounded-b-[23px] rounded-tl-[23px] border border-[rgba(92,34,4,0.60)] flex items-start md:items-center md:justify-end justify-center py-8 px-16'
				>
					<img
						className='absolute bottom-0 right-[50%] translate-x-[50%] md:left-[200px] md:translate-x-0 w-[150px] h-[200px] md:w-auto md:h-auto'
						src='llms-image.png'
					/>
					<div className='w-full md:w-[50%] text-[12px] md:text-[18px] text-[#999999] font-medium'>
						Whether it&apos;s researchers experimenting with new LLM architectures or developers deploying AI
						applications at scale, Aigen Layer provides a unified platform for orchestrating AI workflows and
						accelerating the pace of innovation.
					</div>
				</div>
			</div>
		</div>
	);
};

export default Ecosystem;

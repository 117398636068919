import React, { useCallback } from 'react';
import './App.css';
import HeroComponent from './Components/HeroComponent';
import KeyFeatures from './Components/KeyFeatures';
import MoreFeatures from './Components/MoreFeatures';
import AnotherFeaturesList from './Components/AnotherFeaturesList';
import Layers from './Components/Layers';
import Ecosystem from './Components/Ecosystem';
import ModalComponent from './ui-components/ModalComponent';
import { useGlobalUserDetailsContext } from './context/UserDetailsContext';
import JoinWaitlistEmail from './AppLayout/JoinWaitlistEmail';
import { FIREBASE_URL_PROD } from './constants/firebaseUrls';

function App() {
	const { openJoinWaitlistModal, setOpenJoinWaitlistModal } = useGlobalUserDetailsContext();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const startIncrement = useCallback(async () => {
		const getNodesRes = await fetch(`${FIREBASE_URL_PROD}/incrementNodes`, {
			headers: {
				Accept: 'application/json',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'Content-Type': 'application/json'
			},
			method: 'POST'
		});
		const { data, error } = (await getNodesRes.json()) as {
			data: { nodes: number };
			error: string;
		};

		if (data && !error) {
			console.log(data);
		}
	}, []);

	// useEffect(() => {
	// startIncrement();
	// }, [startIncrement]);
	return (
		<div className='font-sans'>
			<ModalComponent
				open={openJoinWaitlistModal}
				onCancel={() => setOpenJoinWaitlistModal(false)}
				title='Join Waitlist'
			>
				<JoinWaitlistEmail onCancel={() => setOpenJoinWaitlistModal(false)} />
			</ModalComponent>
			<HeroComponent />
			<KeyFeatures />
			<MoreFeatures />
			<AnotherFeaturesList />
			<Ecosystem />
			<Layers />
		</div>
	);
}

export default App;

/* eslint-disable sort-keys */
import { Divider } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import './styles.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const AnotherFeaturesList = () => {
	const sliderRef = useRef<HTMLDivElement>(null);
	const [slides, setSlides] = useState<{ description: string; title: string; url: string; imageURL?: string }[]>([]);

	const isJSON = (data: any) => {
		try {
			JSON.parse(data);
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	const fetchGit = useCallback(async () => {
		const res = await fetch('https://api.github.com/repos/aigen1/aigen1.github.io/contents/docs/_posts');
		const data = (await res.json()) as any[];
		setSlides([]);
		const temp = [];
		if (data && Array.isArray(data)) {
			for (const item of data) {
				const contentRes = await fetch(
					`https://api.github.com/repos/aigen1/aigen1.github.io/contents/docs/_posts/${item.name}`
				);
				const content = await contentRes.text();

				const decoded = isJSON(content) ? atob(JSON.parse(content).content) : content;
				const title = decoded.substring(decoded.indexOf('title: "') + 8, decoded.indexOf('date:') - 2);
				const imageURL = decoded.substring(decoded.indexOf('src="') + 5, decoded.indexOf('width=') - 2);
				const name = item.name as string;
				const a = name.split('-');
				const dates: string[] = [];
				const titles: string[] = [];
				a.forEach((b) => {
					if (Number.isNaN(Number(b))) {
						titles.push(b);
					} else {
						dates.push(b);
					}
				});

				temp.push({
					title,
					imageURL,
					description: '',
					url: `http://blog.aigenlayer.org/blog/${dates.join('/')}/${titles.join('-').split('.')[0]}.html`
				});
			}
			setSlides(temp.reverse());
		}
	}, []);

	useEffect(() => {
		fetchGit();
	}, [fetchGit]);

	const handleOnSlide = (direction: number) => {
		if (sliderRef.current) {
			const sliderList = sliderRef.current;
			const scrollAmount = sliderList.clientWidth * direction;
			sliderList.scrollBy({ left: scrollAmount, behavior: 'smooth' });
		}
	};

	return slides && slides.length > 0 ? (
		<div className='w-full flex flex-col mt-[100px] lg:mt-[120px] px-8 md:px-12'>
			<p className='text-white font-semibold text-[32px] md:text-[40px] lg:text-[50px] mb-3'>Research</p>
			<div
				className='max-w-[100vw] overflow-x-auto [&::-webkit-scrollbar]:hidden'
				ref={sliderRef}
			>
				<div
					className='mt-12 slider-list'
					style={{ display: 'grid', gridTemplateColumns: `repeat(${slides.length},1fr)`, gap: '24px' }}
				>
					{slides.map((item, i) => (
						<div
							key={i}
							className='border border-solid border-[#262626] p-[50px] flex flex-col gap-y-4 h-full w-[400px] rounded-[12px]'
						>
							<p className='text-white font-medium text-[24px]'>{item.title}</p>
							<p className='text-[#999] font-medium text-[18px]'>{item.description}</p>
							<div className='flex-1' />
							<div className='mt-2'>
								<a
									href={item.url}
									target='_blank'
									className='w-[150px] bg-[#1A1A1A] border border-solid border-[#262626] text-white text-[18px] flex justify-center items-center rounded-[10px] px-6 py-3'
									rel='noreferrer'
								>
									Read More
								</a>
							</div>
						</div>
					))}
				</div>
			</div>
			<Divider className='border-[#262626]' />
			<div className='flex justify-between'>
				<div className='flex-1' />
				<div className='flex items-center gap-x-2'>
					<button
						onClick={() => handleOnSlide(-1)}
						className='flex p-4 items-center justify-center border border-solid border-[#262626] bg-[#1A1A1A] rounded-full text-white'
					>
						<ArrowLeftOutlined />
					</button>
					<button
						onClick={() => handleOnSlide(1)}
						className='flex p-4 items-center justify-center border border-solid border-[#262626] bg-[#1A1A1A] rounded-full text-white'
					>
						<ArrowRightOutlined />
					</button>
				</div>
			</div>
		</div>
	) : null;
};

export default AnotherFeaturesList;

import { PrivyProvider } from '@privy-io/react-auth';
import React, { ReactNode } from 'react';
import { UserDetailsContextProvider } from './context/UserDetailsContext';

export default function Providers({ children }: { children?: ReactNode }) {
	const appId = process.env.REACT_APP_PRIVY_APP_ID || '';
	return (
		<PrivyProvider
			appId={appId}
			config={{
				appearance: {
					accentColor: '#38A1FF',
					logo: '/aigen-logo.png',
					theme: '#161616',
					walletList: ['metamask', 'detected_wallets']
				},
				loginMethods: ['wallet']
			}}
		>
			<UserDetailsContextProvider>{children}</UserDetailsContextProvider>
		</PrivyProvider>
	);
}

/* eslint-disable sort-keys */
import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import './styles.css';

const Layers = () => {
	const layers = [
		{
			name: 'Jarvis',
			title: 'Controller Layer',
			subtitle: `Unlike static prompts, AiGen's controller VM offers flexibility and versatility in guiding LLMs'
			behavior, allowing for dynamic editing of prompts and generated text.`
		},
		{
			name: 'Middleware',
			title: 'Adaptive Middleware Layer',
			subtitle: `AiGen's middleware offers composable modularity, enabling LLMs and compute providers such as
			Bittensor's subnets, Hugging Face Transformers, Aakash Network, and others to effortlessly integrate.`
		},
		{
			name: 'Marvin',
			title: 'Scorer Layer',
			subtitle: `AiGen's scorers accept an instruction and response pair, and compute a relevance score ranging from 1
			to 100, reflecting the predicted accuracy of the response relative to the given instruction.`
		}
	];
	const [index, setIndex] = useState<number>(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((prev) => {
				if (prev === 2) {
					return 0;
				}
				return prev + 1;
			});
		}, 4000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className='layer w-full flex flex-col-reverse md:flex-row justify-center items-center md:items-start gap-x-20 gap-y-10 mt-[100px] px-8 md:px-12'>
			<div className='w-[300px] h-[350px] md:w-[400px] md:h-[390px] relative overflow-y-hidden'>
				<div className='wrapper absolute h-full w-full top-[50%] left-[50%] md:block hidden'>
					<div className='first absolute left-[100px]'></div>
					<div className='second to-first absolute top-[100px] left-[120px]'></div>
					<div className='second absolute top-[100px] left-[120px]'></div>
					<div className='third absolute top-[130px] left-[115px]'></div>
					<div className='fourth absolute top-[160px] left-[110px]'></div>
					<div className='fifth absolute top-[190px] left-[105px]'></div>
				</div>
				<div className='wrapper absolute h-full w-full top-[50%] left-[50%] md:hidden block'>
					<div className='first-small absolute left-[75px]'></div>
					<div className='second-small to-first-small absolute top-[80px] left-[95px]'></div>
					<div className='second-small absolute top-[80px] left-[95px]'></div>
					<div className='third-small absolute top-[110px] left-[90px]'></div>
					<div className='fourth-small absolute top-[140px] left-[85px]'></div>
					<div className='fifth-small absolute top-[170px] left-[80px]'></div>
				</div>
			</div>
			<div className='w-full md:w-[40%] flex md:flex-col flex-col-reverse items-center md:items-start gap-y-4 pt-8'>
				<div
					style={{
						background:
							'linear-gradient(0deg, rgba(219, 89, 24, 0.08) 0%, rgba(219, 89, 24, 0.08) 100%), rgba(255, 255, 255, 0.14)'
					}}
					className='rounded-[16px] w-[250px] md:w-[300px] lg:w-[350px] py-4 px-6 relative'
				>
					<Divider
						orientation='right'
						orientationMargin={0}
						dashed
						className='absolute border-[rgba(113,106,102,0.60)] left-[-260px] hidden md:flex'
					>
						<div className='h-3 w-3 rounded-full bg-[rgba(113,106,102,0.60)]' />
					</Divider>
					<span className='font-valo text-center md:text-left text-primary text-[20px] md:text-[24px] lg:text-[28px] font-bold'>
						{layers[index].name}
					</span>
				</div>
				<div className='flex flex-col gap-y-4'>
					<p className='font-bold text-white text-[28px] md:text-[32px] lg:text-[48px]'>{layers[index].title}</p>
					<p className='text-[12px] md:text-[16px] lg-text-[18px] text-[#999999]'>{layers[index].subtitle}</p>
				</div>
			</div>
		</div>
	);
};

export default Layers;

import { Button, Drawer, Dropdown } from 'antd';
import React, { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { useLogin, usePrivy } from '@privy-io/react-auth';
import shortenAddress from '../utils/shortenAddress';
import { useGlobalUserDetailsContext } from '../context/UserDetailsContext';

const JoinWaitlistComponent = ({ className }: { className?: string }) => {
	const { logout, authenticated, ready, user } = usePrivy();

	const { connectWallet, setEmail } = useGlobalUserDetailsContext();

	const { login } = useLogin({
		onComplete(user) {
			connectWallet(user?.wallet?.address || '');
		}
	});
	return !authenticated || !ready ? (
		<Button
			loading={!ready}
			onClick={() => login()}
			className={`connect-button items-center border-none justify-center bg-[rgba(146,146,146,0.20)] rounded-[14px] text-white font-medium text-[16px] px-[24px] py-[18px] ${className}`}
		>
			Join Waitlist
		</Button>
	) : (
		<Dropdown
			trigger={['click']}
			menu={{
				items: [
					{
						key: 'logout',
						label: <p className='text-white text-center'>Logout</p>
					}
				],
				onClick: (e) => {
					if (e.key === 'logout') {
						logout();
						setEmail('');
					}
				}
			}}
		>
			<Button
				onClick={() => login()}
				className={`connect-button items-center border-none justify-center bg-[rgba(146,146,146,0.20)] rounded-[14px] text-white font-medium text-[16px] px-[24px] py-[18px] ${className}`}
			>
				{user ? shortenAddress(user?.wallet?.address || '') : 'Join Waitlist'}
			</Button>
		</Dropdown>
	);
};

const NavHeader = () => {
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);

	return (
		<div className='border border-[#383838] border-solid rounded-[18px] md:rounded-[24px] flex items-center justify-between w-full px-4 bg-[rgba(13,1,27,0.30)] backdrop-blur-sm'>
			<img
				src='aigen-logo.png'
				alt='Aigen Logo'
				className='h-[30px] w-[140px]'
			/>
			<div className='items-center gap-x-5 text-white font-medium text-[18px] hidden md:flex'>
				<span className='nav-text active cursor-pointer'>Home</span>
				<a
					href='http://docs.aigenlayer.org/'
					target='_blank'
					className='nav-text cursor-pointer'
					rel='noreferrer'
				>
					Docs
				</a>
				<span className='text-[#999999] flex items-center gap-x-2'>
					Whitepaper
					<span
						style={{ background: 'linear-gradient(106deg, #FFF -11.29%, #EEB732 49.29%, #E94410 102.26%)' }}
						className='text-black text-[8px] flex items-center justify-center rounded-lg p-2 h-[20px]'
					>
						Coming Soon
					</span>
				</span>
			</div>
			<JoinWaitlistComponent className='hidden md:flex' />

			<button
				className='text-white md:hidden text-[24px]'
				onClick={() => setOpenDrawer(true)}
			>
				<MenuOutlined />
			</button>
			<Drawer
				title={
					<img
						src='aigen-logo.png'
						alt='Aigen Logo'
						className='h-[30px] w-[140px]'
					/>
				}
				placement='top'
				closable={false}
				onClose={() => setOpenDrawer(false)}
				open={openDrawer}
				height={200}
				className='bg-[rgba(13,1,27)] md:hidden'
			>
				<div className='flex flex-col items-center gap-y-4'>
					<div className='flex items-center gap-x-6 text-white font-medium text-[18px] w-full justify-center'>
						<span className='nav-text active cursor-pointer'>Home</span>
						<a
							href='http://docs.aigenlayer.org/'
							target='_blank'
							className='nav-text cursor-pointer'
							rel='noreferrer'
						>
							Docs
						</a>
						<span className='text-[#999999] flex items-center gap-x-2'>
							Whitepaper
							<span
								style={{ background: 'linear-gradient(106deg, #FFF -11.29%, #EEB732 49.29%, #E94410 102.26%)' }}
								className='text-black text-[8px] flex items-center justify-center rounded-lg p-2 h-[20px]'
							>
								Coming Soon
							</span>
						</span>
					</div>
					<JoinWaitlistComponent className='flex' />
				</div>
			</Drawer>
		</div>
	);
};

export default NavHeader;

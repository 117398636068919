import { Divider } from 'antd';
import React from 'react';

const KeyFeatures = () => {
	return (
		<div className='w-full flex flex-col gap-y-5 mt-[100px] lg:mt-[120px]'>
			<div className='flex items-center justify-center gap-x-2'>
				<img
					src='aigen-logo.png'
					alt='Aigen Logo'
					className='h-[30px] w-[140px]'
				/>
				<Divider
					type='vertical'
					className='border-[#999999]'
					style={{ height: '24px' }}
				/>
				<span className='text-[24px] text-[#999999]'>Nexus</span>
			</div>
			<p className='text-white font-semibold text-[32px] md:text-[40px] lg:text-[50px] text-center'>
				We improve your AI&apos;s performance by 3x
			</p>
			<p className='text-[#999999] text-[16px] text-center flex flex-col gap-x-2'>
				<span>
					Use any open-source or closed-source AI stack and plug into our modular stack to supercharge its performance.
				</span>
				<span>Start earning by building custom controllers and scorers.</span>
			</p>
			<div className='w-full bg-[rgba(26,26,26,0.50)] py-2'>
				<div className='w-full bg-[rgba(20,20,20,0.50)] border border-solid border-[#262626] flex items-center justify-around flex-col md:flex-row gap-x-4 gap-y-4 p-4'>
					<div className='bg-[rgba(26,26,26,0.50)] h-[400px] md:h-[500px] rounded-[12px] border border-solid border-[#262626] w-full px-3 py-6 flex flex-col items-center justify-center gap-y-6'>
						<img
							src='jarvis.png'
							alt='Jarvis'
							className='md:h-[200px] md:w-[350px] h-[150px] w-[250]'
						/>
						<span className='text-white font-medium text-[20px] lg:text-[24px]'>Jarvis - Controller Layer</span>
						<span className='text-[#999999] text-[14px] lg:text-[16px] text-center'>
							Unlike static prompts, AiGen&apos;s controller VM offers flexibility and versatility in guiding LLMs&apos;
							behavior, allowing for dynamic editing of prompts and generated text.
						</span>
					</div>
					<div className='bg-[rgba(26,26,26,0.50)] h-[450px] md:h-[500px] rounded-[12px] border border-solid border-[#262626] w-full p-3 flex flex-col items-center justify-center gap-y-6'>
						<img
							src='middleware.png'
							alt='Middle Layer'
							className='md:h-[250px] md:w-[235px] h-[200px] w-[185px]'
						/>
						<span className='text-white font-medium text-[20px] lg:text-[24px]'>Adaptive Middleware Layer</span>
						<span className='text-[#999999] text-[14px] lg:text-[16px] text-center'>
							AiGen&apos;s middleware offers composable modularity, enabling LLMs and compute providers such as
							Bittensor&apos;s subnets, Hugging Face Transformers, Aakash Network, and others to effortlessly integrate.
						</span>
					</div>
					<div className='bg-[rgba(26,26,26,0.50)] h-[400px] md:h-[500px] rounded-[12px] border border-solid border-[#262626] w-full p-3 flex flex-col items-center justify-center gap-y-6'>
						<img
							src='marvin.png'
							alt='Marvin'
							className='md:h-[250px] md:w-[235px] h-[200px] w-[185px]'
						/>
						<span className='text-white font-medium text-[20px] lg:text-[24px]'>Marvin - Scorer Layer</span>
						<span className='text-[#999999] text-[14px] lg:text-[16px] text-center'>
							AiGen&apos;s scorers accept an instruction and response pair, and compute a relevance score ranging from 1
							to 100, reflecting the predicted accuracy of the response relative to the given instruction.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default KeyFeatures;

/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import './styles.css';

const NUMBER_OF_SLIDES = 4;

const Banner = ({
	images,
	duration = 16
}: {
	images: { src: string; height: number; width: number }[];
	duration?: number;
}) => {
	const maxWidth = images.reduce((sum, item) => sum + item.width, 0);
	return (
		<div
			className={'slides-wrap flex overflow-hidden absolute'}
			style={{ width: maxWidth * NUMBER_OF_SLIDES * 2 }}
		>
			{Array(NUMBER_OF_SLIDES)
				.fill(NUMBER_OF_SLIDES)
				.map((item, i) => (
					<section
						key={i}
						style={{ animation: `swipe ${duration}s linear infinite backwards` }}
						className='px-[30px]'
					>
						{images.map((image, i) => (
							<div
								style={{ width: `${image.width}px` }}
								className={`h-[${image.height}px]`}
								key={i}
							>
								<img
									src={image.src}
									alt='ecosystem'
								/>
							</div>
						))}
					</section>
				))}
		</div>
	);
};

export default Banner;
